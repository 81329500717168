import { BookOpenIcon } from '@heroicons/react/24/solid';
import {
  CardWrapperWithHeader,
  ButtonIcon,
  Heading,
  Button,
} from '@rabbit/elements/shared-components';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { useTranslation } from 'react-i18next';

export default function LegalTab() {
  const { t } = useTranslation();
  const buttons = [
    {
      kind: 'bgLightGreen' as const,
      label: t('general.privacyPolicy'),
      onClick: () => window.open(`${SAGE_ROUTE_NAME.PRIVACY_POLICY}`, '_blank'),
      Icon: BookOpenIcon,
    },
    {
      kind: 'bgLightGreen' as const,
      label: t('general.termsAndConditions'),
      onClick: () =>
        window.open(`${SAGE_ROUTE_NAME.TERMS_AND_CONDITIONS}`, '_blank'),
      Icon: BookOpenIcon,
    },
    // {
    //   kind: 'bgLightGreen' as const,
    //   label: 'FAQs',
    //   onClick: () => navigate('/'),
    //   Icon: BookOpenIcon,
    // },
    // {
    //   kind: 'bgLightGreen' as const,
    //   label: 'Documentation',
    //   onClick: () => navigate('/'),
    //   Icon: BookOpenIcon,
    // },
  ];
  return (
    <>
      <div className="flex flex-col items-center gap-2">
        <Heading kind="h2">{t('general.legal')}</Heading>
        <p className="font-nunito text-lg text-gray-500">
          {t('message.seePrivacyPolicyAndTerms')}
        </p>
      </div>
      <div className="mx-auto mt-8 lg:w-[860px]">
        <CardWrapperWithHeader title={t('general.legal')}>
          <div className="flex flex-col gap-5 md:flex-row">
            {buttons.map((button, i) => (
              <Button
                key={i}
                icon={<button.Icon />}
                kind="primary"
                onClick={button.onClick}
                className="!bg-primary-600 !hover:bg-primary-900 h-14"
              >
                {button.label}
              </Button>
            ))}
          </div>
        </CardWrapperWithHeader>
      </div>
    </>
  );
}
