import {
  CURRENCY_TYPES,
  DTConsumer_Private,
  DTHoldingProxy,
  DTPaymentRequest,
  DTService_Offer_With_Template,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
  TenantLink,
} from '@rabbit/data/types';
import { LIST_CURRENCIES, useGetConsumerData } from '@rabbit/bizproc/react';
import {
  BL_Service,
  nestApiCreatePaymenRequest,
  nestApiGetAllServiceOffers,
  PaymentRequestBodyPayload,
  getAllServiceRequestsForConsumer,
  cancelPaymentRequest,
  PaymentRequestCancelBodyPayload,
  RegisterHoldingsServicePayload,
  nestApiRegisterHoldingsService,
} from '@rabbit/bizproc/core';
import {
  Button,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@rabbit/app-context';
import { useContext, useEffect, useState } from 'react';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import { toast } from 'react-toastify';
import ServicePlanCard from 'libs/elements/shared-components/src/lib/molecules/ServicePlanCard/ServicePlanCard';

export interface ModalAddNewServiceProps {
  handleClose: any;
  holdingId: string;
  consumerLink: string;
  tenantLink: TenantLink;
  holdingProxy?: DTHoldingProxy;
  consumePrivate?: DTConsumer_Private;
  shouldRefresh?: any;
}

const ModalAddNewService = ({
  handleClose,
  tenantLink,
  holdingId,
  holdingProxy,
  consumePrivate,
  shouldRefresh,
  consumerLink,
}: ModalAddNewServiceProps) => {
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const [allPaymentRequests, setAllPaymentRequests] = useState<
    DTPaymentRequest[]
  >([]);

  const [availableOffers, setAvailableOffers] = useState<
    DTService_Offer_With_Template[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Fetch relevant data either from props or the consumer data hook
  const data =
    holdingProxy && consumePrivate
      ? { holding_proxy: holdingProxy, consumer_private: consumePrivate }
      : useGetConsumerData(holdingId);
  const { holding_proxy, consumer_private } = data ?? {};
  const activedServicePlans = BL_Service.getLatestService(
    holding_proxy?.services
  );

  // Function to fetch available service offers from the API
  const getServiceOfferWithTemplate = async () => {
    setLoading(true);
    const res: any = await nestApiGetAllServiceOffers(
      `tenantLink=${tenantLink}`
    );
    if (res) {
      setAvailableOffers(res.data as DTService_Offer_With_Template[]);
    }
    setLoading(false);
  };

  // Function to handle the activation of a service plan
  const handlePaymentRequestPlan = async (
    plan: DTService_Offer_With_Template
  ) => {
    try {
      setLoading(true);

      const hasPendingPayment = allPaymentRequests.filter(
        (request) => request.metadata.templateLink === plan.templateLink
      );
      let response;
      if(plan.price.amount === 0){
        addFreeServicePlan(plan);
      }
      else if (hasPendingPayment && hasPendingPayment.length > 0) {
        const payload: PaymentRequestCancelBodyPayload = {
          warrantorLink: getRootPersonaFromLexicon(
            t(PrincipalsFieldName),
            PersonaTypeSingleLetter.Warrantor
          ),
        };
        response = await cancelPaymentRequest(
          hasPendingPayment[0].docid,
          payload
        );
        await getServiceRequests();
      } else {
        const payload: PaymentRequestBodyPayload = {
          holdingLink: holdingId,
          consumerLink,
          amount: plan.price.amount,
          currency: plan.price.currency.toUpperCase() as CURRENCY_TYPES,
          warrantorLink: getRootPersonaFromLexicon(
            t(PrincipalsFieldName),
            PersonaTypeSingleLetter.Warrantor
          ),
          description: plan.template.description,
          metadata: {
            holdingLink: holdingId,
            consumerLink: consumerLink,
            warrantorLink: getRootPersonaFromLexicon(
              t(PrincipalsFieldName),
              PersonaTypeSingleLetter.Warrantor
            ),
            templateLink: plan.templateLink,
            tenantLink: tenantLink,
          },
        };
        response = await nestApiCreatePaymenRequest(payload);
      }

      // Stop loading and handle the response
      setLoading(false);

      // Check if the response was successful and perform necessary actions
      if (response && response.data) {
        if (typeof response.data === 'string') {
          toast.success(t(response.data as string));
          handleClose(); // Close the modal after successful activation
          return;
        }
        // if (shouldRefresh) shouldRefresh(res.holdingProxy?.services);
        toast.success(t('Payment request successfully added'));
        handleClose(); // Close the modal after successful activation
      }
    } catch (error) {
      // Handle any errors that occur during the request
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tenantLink) getServiceOfferWithTemplate(); // Fetch service offers whenever tenantLink changes
  }, [tenantLink]);

  const getFormatedPrice = (price: any) => {
    const currencyCode = price ? price.currency : tenantInfo?.currency;
    const currency = LIST_CURRENCIES.find((item) => item.code === currencyCode);

    return currency
      ? `${currency.code} ${currency.symbol} ${price?.amount}`
      : 'Free';
  };

  const getServiceRequests = async () => {
    const response: DTPaymentRequest[] =
      await getAllServiceRequestsForConsumer(consumerLink as string);
    if (response.length > 0) {
      setAllPaymentRequests(response);
    }else{
      setAllPaymentRequests([]);
    }
  };

  const addFreeServicePlan = async (plan:DTService_Offer_With_Template) => {
    const payload:RegisterHoldingsServicePayload = {
      holdingLink: holdingId,
      consumerLink: consumerLink,
      warrantorLink: getRootPersonaFromLexicon(
        t(PrincipalsFieldName),
        PersonaTypeSingleLetter.Warrantor
      ),
      price: {
        amount: 0,
        currency: tenantInfo?.currency as CURRENCY_TYPES,
      },
      tenantLink: tenantLink,
      templateLink: plan.templateLink,
    };
    const response = await nestApiRegisterHoldingsService(payload);
    if(response){
      toast.success(t('Service plan added successfully'));
      handleClose();
    }
  };

  useEffect(() => {
    if (consumer_private) {
      getServiceRequests();
    }
  }, [consumer_private]);

  return (
    <Modal
      className="relative w-[450px]"
      kind="generic"
      settings={{
        title: t('general.selectRoadsideAssistancePlan'),
        handleClose,
        childrenClassName: 'relative',
      }}
    >
      {loading && (
        <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center rounded-md bg-white/50">
          <LoadingSpinner size="md" />
        </div>
      )}
      <div className="w-full space-y-4 p-4">
        {/* Loop through the available service plans and render them */}
        {availableOffers && availableOffers.length > 0 ? (
          availableOffers.map((plan, i) => {
            const hasPendingPayment = allPaymentRequests.some(
              (request) => request.metadata.templateLink === plan.templateLink
            );
            return (
              <ServicePlanCard
                key={plan.docid}
                plan={plan}
                paymentStatus={
                  hasPendingPayment
                    ? t('Cancel Payment')
                    : activedServicePlans?.templateLink === plan.templateLink
                    ? t('Activated')
                    : t('Request for Payment')
                }
                disabled={
                  (allPaymentRequests.length>0 && !hasPendingPayment) ||
                  activedServicePlans?.templateLink === plan.templateLink
                }
                onActivate={handlePaymentRequestPlan}
              />
            );
          })
        ) : (
          <p>{t('There is no offers available for this Dealer')}</p>
        )}
      </div>
    </Modal>
  );
};

export default ModalAddNewService;
