import { Heading, Input } from '@rabbit/elements/shared-components';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { LogForms, inputTypeCurrencyOverrideOptions } from '../ClaimCostModal';
import { CaseflowInterface } from '@rabbit/sage/context/CaseflowContext';
import { OptionShape } from '@rabbit/bizproc/react';
import { CFCF_AdministrativeCostLog } from '@rabbit/bizproc/core';
import { t } from 'i18next';
import { ConfigInterface } from '@rabbit/app-context';
import {
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
  TenantInfo,
} from '@rabbit/data/types';

const AdministrativeTimeForm = (
  tenantInfo: TenantInfo | undefined,
  config: ConfigInterface['config'],
  { caseFacts, alterCaseFacts }: CaseflowInterface,
  repairerUsers: OptionShape[],
  personaId: string,
  installerUsers: OptionShape[],
  options?: { editMode: boolean; index: number }
): LogForms<CFCF_AdministrativeCostLog & { team_member_id: string }> => {
  return {
    name: 'administrative-time',
    schema: Yup.object({
      description: Yup.string().required(t('Please enter description')).trim(),
      team_member_id: Yup.string().required(t('Please select a team member')),
      time_spent: Yup.string().required(t('Please enter the time')).trim(),
      date: Yup.string().trim(),
      rate: Yup.object({
        amount: Yup.string().required(t('Please enter an amount')),
        currency: Yup.string().required(t('Please select the currency')),
      }),
      VAT: Yup.string()
        .required(t(`Please enter ${config.ACCOUNT.TAX_LABEL}`))
        .trim(),
    }),
    initialValues: {
      rate: {
        currency: tenantInfo?.currency,
      },
    },
    onDelete: async (index, onComplete) => {
      const updatedRegistry = caseFacts?.administrative_cost_data || [];
      updatedRegistry.splice(index, 1);
      try {
        await alterCaseFacts({
          administrative_cost_data: [...updatedRegistry],
        });
        toast.success(t('Record deleted successfully.'));
      } catch (err) {
        console.log(err);
        toast.error(t('Something went wrong, please try again'));
      } finally {
        onComplete();
      }
    },
    onSubmit: async (values, onComplete) => {
      let delegate = {} as OptionShape | undefined;
      if (config.HOLDINGS.INSTALLATION_REQUIRED) {
        delegate = installerUsers.find(
          (user) => user.value === values.team_member_id
        );
      } else {
        delegate = repairerUsers.find(
          (user) => user.value === values.team_member_id
        );
      }
      const record = values;
      record.team_member = {
        id: delegate?.value,
        name: delegate?.label || '',
      };
      record.date = new Date(values.date || 0).getTime();

      let updatedRegistry = [];
      if (options?.editMode) {
        updatedRegistry = caseFacts?.administrative_cost_data || [];
        updatedRegistry[options.index] = record;
      } else {
        updatedRegistry = [
          ...(caseFacts?.administrative_cost_data || []),
          record,
        ];
      }

      try {
        await alterCaseFacts({
          administrative_cost_data: [...updatedRegistry],
          ...(values.internal_comment && {
            internal_comment: {
              comment: values.internal_comment,
              author: personaId,
            },
          }),
        });
        toast.success(t('Claim updated successfully.'));
      } catch (err) {
        console.log(err);
        toast.error(t('Something went wrong, please try again'));
      } finally {
        onComplete();
      }
    },
    Form: () => {
      return (
        <>
          <div className="font-nunito font-lg font-bold">
            {t('Administrative time')}
          </div>

          <Input
            type="select"
            name="team_member_id"
            label={t('Team member' + '*')}
            settings={{
              id: 'team_member',
              placeholder: t('Select a team member'),
              options: config.HOLDINGS.INSTALLATION_REQUIRED
                ? installerUsers
                : repairerUsers,
            }}
          />
          <div className="grid grid-cols-2 gap-y-2 gap-x-4">
            <Input
              type="datepicker"
              name="date"
              label={t('Date')}
              settings={{
                id: 'date',
                placeholder: 'DD/MM/YYYY',
              }}
            />
            <Input
              type="time"
              name="time_spent"
              label={t('Time') + '*'}
              settings={{
                id: 'time',
                placeholder: 'hh:mm',
              }}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Input
              type="currency"
              name="rate"
              label={t('Rate')}
              settings={{
                id: 'rate',
                placeholder: t('Rate amount'),
                inputTypeCurrencyOverrideOptions,
                disableCurrency: true,
              }}
            />
            <Input
              type="text"
              name="VAT"
              label={config.ACCOUNT.TAX_LABEL + '*'}
              settings={{
                id: 'vat',
                placeholder: t('Amount'),
                append: '%',
              }}
            />
          </div>
          <Input
            type="text"
            label={t('Description') + '*'}
            name="description"
            settings={{
              placeholder: t('Enter description here'),
            }}
          />
          <Heading kind="p" className="text-gray-500">
            {'*' + t('required')}
          </Heading>
        </>
      );
    },
  };
};

export default AdministrativeTimeForm;
