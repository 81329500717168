import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ConsumerIssueTypeHookOrigins,
  LIST_COUNTRIES,
  useManageConsumerIssueTypes,
  useSageAPI,
} from '@rabbit/bizproc/react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { NewClaimModalSteps } from '../../../ModalNewClaim/ModalNewClaim';
import {
  DTConsumer_Public,
  DTHoldingProxy,
  GenerateTenantRootPersonaLink,
  PersonaTypeFullKeyValues,
} from '@rabbit/data/types';
import {
  Caseflow_CaseCreation_Sage_Shelta_FormDataShape,
  CaseflowCaseTypes,
  RegisterCaseOrigins,
} from '@rabbit/bizproc/core';
import { OurDateTime } from '@rabbit/utils/ts';
import { AppContext } from '@rabbit/app-context';

export interface NCCHF_BaseProps {
  onHoldingCreated: (result: any) => void;
  handleClose: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onChange: (step: NewClaimModalSteps, data?: any) => void;
  data: { holding: DTHoldingProxy; consumer: DTConsumer_Public };
}

// TODO: EXTRACT GENERIC BITS OF THIS AND NCCHF_FATBIKES INTO A SHARED COMPONENT

interface HoldingDataCase {
  purchase_date: Date;
  consumer_issue_type_ref: string;
  purchase_location: {
    country: string;
    docid: string;
  } | null;
  store_not_listed: boolean;
  custom_store_name: string;
  product_country: string;
  consumer_issue_description: string;
  holding_vendable_id: string;
  internal_comment: string;
}

export function NCCHF_Base(props: NCCHF_BaseProps) {
  const { handleClose, onChange, data, onHoldingCreated, setLoading } = props;

  const formikRef = useRef(null) as any;
  const { t } = useTranslation();
  const activePremiumTenantLink = t('tenantLink');
  const caseFlowType = t('CFG_CLAIMS_FLOW') as CaseflowCaseTypes;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDatePurchaseDisabled, setIsDatePurchaseDisabled] = useState(false);

  const { config } = useContext(AppContext);

  const { initiateCaseFlowCase } = useSageAPI();

  const { consumerIssueTypeList } = useManageConsumerIssueTypes(
    ConsumerIssueTypeHookOrigins.SAGE
  );
  const consumerIssueTypeOptions = !consumerIssueTypeList?.data
    ? []
    : consumerIssueTypeList.data.map((issue) => ({
        id: issue.docid,
        value: issue.docid,
        label: issue.label,
      })) ?? [];

  const initialValues: HoldingDataCase = {
    holding_vendable_id: '',
    purchase_date: new Date(),
    consumer_issue_type_ref: '',
    purchase_location: null,
    store_not_listed: false,
    custom_store_name: '',
    product_country: '',
    consumer_issue_description: '',
    internal_comment: '',
  };

  const validationSchema = Yup.object().shape(
    data.holding
      ? {
          consumer_issue_type_ref: Yup.string()
            .trim()
            .required(t('Issue type must be selected')),
          consumer_issue_description: Yup.string().trim(),
          internal_comment: Yup.string().trim(),
        }
      : {
          holding_vendable_id: Yup.string()
            .trim()
            .required(t("Product name can't be empty")),
          purchase_date: Yup.string().nullable().trim(),
          consumer_issue_type_ref: Yup.string()
            .trim()
            .required(t('Issue type must be selected')),
          purchase_location: Yup.mixed(),
          store_not_listed: Yup.boolean(),
          consumer_issue_description: Yup.string().trim(),
          internal_comment: Yup.string().trim(),
        }
  );

  // TODO: check this on submit func to save the case
  const onSubmit = async (values: HoldingDataCase) => {
    setIsSubmitting(true);
    setLoading(true);

    // If values include a retailer from the database, then purchase_location_other should be cleared - and vice versa
    const newPurchaseLocation =
      values.custom_store_name && values.store_not_listed
        ? null
        : values.purchase_location?.docid ?? '';

    const newPurchaseCountry =
      values.custom_store_name && values.store_not_listed
        ? values.product_country
        : values.purchase_location?.country ?? '';

    const newPurchaseLocationOther = values.store_not_listed
      ? values.custom_store_name ?? ''
      : null;

    let finalData: Caseflow_CaseCreation_Sage_Shelta_FormDataShape =
      data.holding
        ? {
            consumer_first_name: data.consumer.splitname?.first,
            consumer_last_name: data.consumer.splitname?.last,
            consumer_address: data.consumer.address,
            consumer_email: data.consumer.email,
            consumer_telephone: data.consumer.phone,
            consumer_preferred_contact: data.consumer.preferred_contact,
            holding_vendable_id: data.holding.vendable,
            consumer_holding_purchase_location: newPurchaseLocation,
            consumer_holding_purchase_country: newPurchaseCountry,
            consumer_holding_purchase_location_other: newPurchaseLocationOther,
            purchase_date: data.holding.purchase_time,
            consumer_issue_type:
              consumerIssueTypeOptions.find(
                (option) => option.id === values.consumer_issue_type_ref
              )?.label ?? '',
            internal_comment: values.internal_comment ?? '',
            consumer_issue_description: values.consumer_issue_description,
          }
        : ({
            ...data,
            ...values,
            consumer_holding_purchase_location: newPurchaseLocation,
            consumer_holding_purchase_country: newPurchaseCountry,
            consumer_holding_purchase_location_other: newPurchaseLocationOther,
            purchase_date: values.purchase_date
              ? OurDateTime.dateToTimestamp(values.purchase_date)
              : null,
            consumer_issue_type:
              consumerIssueTypeOptions.find(
                (option) => option.id === values.consumer_issue_type_ref
              )?.label ?? '',
            consumer_issue_description: values.consumer_issue_description,
          } as any);

    finalData.consumer_issue_type_ref = values.consumer_issue_type_ref;
    try {
      const res = await initiateCaseFlowCase({
        origin:
          caseFlowType == CaseflowCaseTypes.SHELTA
            ? RegisterCaseOrigins.SAGE_SHELTA
            : RegisterCaseOrigins.SAGE_LITE,
        caseType: caseFlowType,
        tenantLink: activePremiumTenantLink,
        consumerLink: data.consumer?.docid,
        holdingLink: data.holding?.holdingLink,
        formData: finalData,
        installerLink: data.holding?.installer ?? '',
        warrantorLink: GenerateTenantRootPersonaLink(
          PersonaTypeFullKeyValues.warrantor,
          activePremiumTenantLink
        ),
      });

      if (res.holdingLink && res.consumerLink) {
        onHoldingCreated({
          case_id: res.caseId,
          consumer_persona_id: res.consumerLink,
          holding_id: res.holdingLink,
        });
      }
      if (config.CLAIMS.CLAIMS_FLOW.CLAIM_UPLOAD) {
        onChange('claim-upload');
      } else {
        handleClose();
      }
    } catch (err: any) {
      console.log(err);
      toast.error(err?.message ?? t('Something went wrong'));
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formikRef && formikRef.current && isDatePurchaseDisabled) {
      formikRef.current.setFieldValue('purchase_date', null);
    }
  }, [isDatePurchaseDisabled]);

  if (!config.CLAIMS.CLAIMS_FLOW.CREATE_PRODUCT) {
    if (!data.holding) {
      return (
        <div className="p-5">
          <p className="pb-3">{t('general.noValidProductsFound')}</p>
          <div className="flex">
            <Button kind="primary" onClick={() => onChange('select-user')}>
              {t('general.searchAgain')}
            </Button>
          </div>
        </div>
      );
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
    >
      {({ errors, values }) => (
        <Form className="flex-col gap-3 px-5">
          <div className="mt-4 grid grid-cols-2 gap-4">
            {!data.holding && (
              <>
                <Input
                  type="autocomplete-vendable"
                  name="holding_vendable_id"
                  label={t('Product') + '*'}
                  settings={{
                    isMulti: false,
                    id: 'holding_vendable_id',
                    placeholder: t('Product name'),
                    options: [],
                    errors: errors,
                    hint: '*' + t('required'),
                    tenantLink: t('tenantLink'),
                  }}
                />
                <div className="w-full flex-col">
                  <Input
                    type="datepicker"
                    name="purchase_date"
                    label={t('Date of purchase')}
                    settings={{
                      id: 'purchase_date',
                      placeholder: t('Date of purchase'),
                      maxDate: new Date(),
                      disabled: isDatePurchaseDisabled,
                    }}
                  />
                  <div className="flex items-center pt-2">
                    <Input
                      type="checkbox"
                      name="noDateOfPurchase"
                      settings={{
                        checkboxLabel: t('Date not known'),
                        checkboxLabelStyles: 'text-base text-gray-500',
                        onChange: () =>
                          setIsDatePurchaseDisabled(!isDatePurchaseDisabled),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className={`mt-4 grid gap-4 ${
              data.holding ? 'grid-cols-1' : 'grid-cols-2'
            } `}
          >
            <Input
              type="select"
              name="consumer_issue_type_ref"
              label={t('Customer issue type' + '*')}
              settings={{
                isMulti: false,
                id: 'consumer_issue_type_ref',
                placeholder: t('Product defect'),
                options: consumerIssueTypeOptions,
                errors: errors,
                hint: '*required',
              }}
            />
            {!data.holding && (
              <div className="flex grow flex-col">
                <Input
                  type="autocomplete-location-retailer"
                  name="purchase_location"
                  label={t('Store of purchase')}
                  settings={{
                    isMulti: false,
                    placeholder: t('Please select an option'),
                    options: [],
                    errors: errors,
                    disabled: values.store_not_listed,
                  }}
                />
                <Input
                  type="checkbox"
                  name="store_not_listed"
                  settings={{
                    checkboxLabel: t('Store is not listed'),
                    checkboxLabelStyles: 'text-base text-gray-500',
                  }}
                />
                {values.store_not_listed && (
                  <div className="mt-2 flex flex-col gap-2">
                    <Input
                      type="text"
                      name="custom_store_name"
                      settings={{
                        id: 'custom_store_name',
                        placeholder: t('Enter store name'),
                      }}
                    />
                    <Input
                      type="select"
                      label=""
                      name="purchase_country"
                      settings={{
                        options: LIST_COUNTRIES,
                        id: 'purchase_country',
                        placeholder: t('Country where product was purchased'),
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="mt-4 flex items-center gap-4">
            <Input
              type="text"
              name="consumer_issue_description"
              label={t('Customer issue description')}
              settings={{
                id: 'consumer_issue_description',
                placeholder: t('Enter text here'),
                allowSpecialCharacter: true,
              }}
            />
          </div>
          <div className="mt-4 flex items-center gap-4">
            <Input
              type="text"
              name="internal_comment"
              label={t('Internal comment')}
              settings={{
                id: 'internal_comment',
                placeholder: t('Enter text here'),
                allowSpecialCharacter: true,
              }}
            />
          </div>
          <div className="mt-5 flex w-full gap-4">
            <Button kind="primary" type="submit" loading={isSubmitting}>
              {t('Create claim')}
            </Button>
            <Button kind="outline_red" type="submit" onClick={handleClose}>
              {t('Cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default NCCHF_Base;
