import { ClockIcon } from '@heroicons/react/24/outline';
import { formatDate, titleCase } from './helpers';
import i18next from 'i18next';

const renderStatus = (status: string) => (
  <div
    className={`inline-flex rounded-md py-[2px] px-3 ${
      status === 'voided' || status === 'expired'
        ? 'bg-red-100 text-red-800'
        : ''
    } ${status === 'active' ? 'bg-green-100 text-green-800' : ''} ${
      status === 'upcoming' ? 'bg-orange-100 text-orange-800' : ''
    }`}
  >
    {status ? titleCase(status) : '-'}
  </div>
);

export const RegistrationTableColumns = {
  Default: (t: any, config: any) => [
    {
      title: 'Timestamp',
      header: 'Timestamp',
      Header: <ClockIcon className="h-4 w-4" />,
      accessorKey: 'timeStamp',
      size: 20,
      enableColumnFilter: false,
      filterFn: 'equals',
    },
    {
      title: 'Warranty id',
      header: t('general.warrantyId'),
      accessorKey: 'warrantyId',
      size: 20,
    },
    {
      title: 'Product',
      header: t('general.product'),
      accessorKey: 'product',
      filterVariant: 'multi-select',
    },
    {
      header: t('general.modelNumber'),
      accessorKey: 'modelNum',
      filterVariant: 'text',
    },
    {
      header: t('general.serialNumber'),
      accessorKey: 'serialNum',
      filterVariant: 'text',
      size: 20,
    },
    {
      header: t('general.purchaseDate'),
      accessorKey: 'purchaseDate',
      filterVariant: 'date-range',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: t('general.registered'),
      accessorKey: 'registered',
      filterVariant: 'date-range',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: t('general.expiring'),
      accessorKey: 'expiring',
      size: 20,
      filterVariant: 'date-range',
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    ...(config.HOLDINGS.INSTALLATION_REQUIRED
      ? [
          {
            header: t('general.installer'),
            accessorKey: 'installer',
            size: 20,
            filterVariant: 'multi-select',
          },
        ]
      : []),
    {
      header: t('general.term'),
      accessorKey: 'term',
      enableColumnFilter: false,
      size: 90,
    },
    {
      header: t('general.extended'),
      accessorKey: 'extended',
      size: 10,
      filterFn: 'equals',
      filterVariant: 'select',
    },
    // {
    //   accessorKey: 'actions',
    //   size: 15,
    //   enableColumnActions: false,
    // },
  ],
  CarColumns: [
    {
      title: 'Timestamp',
      header: 'Timestamp',
      Header: <ClockIcon className="h-4 w-4" />,
      accessorKey: 'timeStamp',
      size: 20,
    },
    {
      header: 'ID',
      accessorKey: 'warrantyId',
      size: 20,
    },
    {
      header: 'Customer name',
      accessorKey: 'customerName',
      size: 20,
    },
    {
      title: 'plate',
      header: 'plate',
      accessorKey: 'registrationPlate',
      size: 20,
    },
    {
      title: 'Make',
      header: 'make',
      accessorKey: 'make',
      size: 20,
    },
    {
      header: 'registered',
      accessorKey: 'registered',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: 'Start',
      accessorKey: 'start',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: 'plan',
      accessorKey: 'warrantyPlan',
      size: 25,
      Cell: ({ cell }: any) =>
        cell.getValue() === 'voided' ? renderStatus('voided') : cell.getValue(),
    },
    {
      header: 'term',
      accessorKey: 'term',
      size: 10,
    },
    {
      header: 'Claim limit',
      accessorKey: 'claimLimit',
      size: 20,
    },
    {
      title: 'Partner',
      header: 'partner',
      accessorKey: 'partner',
      size: 20,
    },
    {
      title: 'Salesperson',
      header: 'salesperson',
      accessorKey: 'salesPerson',
      size: 20,
    },
    {
      title: 'Price',
      header: 'price',
      accessorKey: 'price',
      size: 40,
    },
  ],
  TableTop: [
    {
      title: 'Timestamp',
      header: 'Timestamp',
      Header: <ClockIcon className="h-4 w-4" />,
      accessorKey: 'timeStamp',
      size: 20,
    },
    {
      title: 'id',
      header: 'id',
      accessorKey: 'id',
      size: 100,
    },
    {
      header: 'Customer name',
      accessorKey: 'customerName',
      size: 20,
    },
    {
      title: 'Product',
      header: 'product',
      accessorKey: 'product',
      size: 20,
    },
    {
      title: 'Area',
      header: 'area',
      accessorKey: 'area',
      size: 10,
    },
    {
      header: 'registered',
      accessorKey: 'registered',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: 'Start',
      accessorKey: 'start',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: 'service plan',
      accessorKey: 'warrantyPlan',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() === 'voided' ? renderStatus('voided') : cell.getValue(),
    },
    {
      header: 'salesperson',
      accessorKey: 'salesperson',
      size: 20,
    },
    {
      header: 'term (y)',
      accessorKey: 'term',
      size: 90,
    },
    {
      title: 'Partner',
      header: 'partner',
      accessorKey: 'partner',
      size: 20,
    },
  ],
};
