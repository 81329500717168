import { Form, Formik, FormikProps } from 'formik';
import {
  Button,
  getCountryByLabel,
  Input,
  InputTypeSelectAddress,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useEffect, useRef, useState } from 'react';
import { Address } from '@rabbit/data/types';
import { SelectAddressShape } from '@rabbit/elements/shared-types';
import { LIST_COUNTRIES } from '@rabbit/bizproc/react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@rabbit/app-context';

const validationSchema = Yup.object().shape({
  line1: Yup.string().trim().required('Please enter an address.'),
  line2: Yup.string().trim(),
  town: Yup.string().trim().required('Please enter a city / suburb.'),
  state: Yup.string().trim().required('Please enter a state / territory.'),
  postcode: Yup.string().trim().required('Please enter a post code.'),
  country: Yup.string().trim().required('Please enter a country.'),
});

export function ModalEditAddAddress({
  handleClose,
  address,
  handleSubmit,
}: {
  handleClose: () => void;
  address: Address | undefined;
  handleSubmit: (address: Address) => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formikRef = useRef<FormikProps<Address>>(null);
  const { tenantInfo } = useContext(AppContext);

  const initialValues: Address = {
    line1: address?.line1 || '',
    line2: address?.line2 || '',
    town: address?.town || '',
    state: address?.state || '',
    postcode: address?.postcode || '',
    country: tenantInfo?.country || '',
  };

  const handleSelectorChange =
    (props: FormikProps<Address>) => async (address?: SelectAddressShape) => {
      if (address) {
        // props.resetForm();
        await props.setFieldValue('line1', address.line_1);
        await props.setFieldValue('line2', address.line_2);
        await props.setFieldValue('town', address.locality);
        await props.setFieldValue('state', address.province_name);
        await props.setFieldValue('postcode', address.postal_code);
        await props.setFieldValue(
          'country',
          getCountryByLabel(address.country_name)?.value
        );
        props.setErrors({});
      }
    };

  useEffect(() => {
    void (async () => {
      if (address?.line1 && formikRef.current) {
        console.log('address', address);
        await formikRef.current.setFieldValue('line1', address?.line1);
        await formikRef.current.setFieldValue('line2', address?.line2);
        await formikRef.current.setFieldValue('town', address?.town);
        await formikRef.current.setFieldValue('state', address?.state);
        await formikRef.current.setFieldValue('postcode', address?.postcode);
        await formikRef.current.setFieldValue('country', address?.country);
      } else {
        await formikRef.current?.setFieldValue('country', tenantInfo?.country);
        formikRef.current?.setErrors({});
      }
    })();
  }, [formikRef.current]);

  const handleFormSubmit = async (values: Address) => {
    setIsSubmitting(true);
    await handleSubmit({ ...address, ...values });
    setIsSubmitting(false);
    handleClose();
  };

  const { t } = useTranslation();

  return (
    <Modal
      kind="generic"
      settings={{
        title: address ? t('general.editAddress') : t('general.addAddress'),
        handleClose,
      }}
      isLoading={isSubmitting}
      className="m-auto w-[724px] rounded-md border bg-white"
    >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
        innerRef={formikRef}
      >
        {(props) => {
          return (
            <Form className="mt-2 flex w-full flex-col gap-4 px-5">
              <Input
                type="select"
                label={t('general.country')}
                name="country"
                settings={{
                  options: LIST_COUNTRIES,
                  placeholder: 'Country',
                  values: address?.country,
                }}
              />
              <div>
                <InputTypeSelectAddress
                  errors={Object.values(props.errors).join(' ')}
                  onChange={handleSelectorChange(props)}
                  country={props.values.country || tenantInfo?.country}
                />
              </div>
              <div>
                <Input
                  type="text"
                  label={t('general.addressLine')}
                  name="line1"
                  settings={{
                    placeholder: t('general.addressLine'),
                  }}
                />
              </div>
              <div>
                <Input
                  type="text"
                  label={t('general.addressLine2')}
                  name="line2"
                  settings={{
                    placeholder: t('general.addressLine2'),
                  }}
                />
              </div>
              <div className="flex gap-3">
                <Input
                  type="text"
                  label={t('general.citySuburb')}
                  name="town"
                  settings={{ placeholder: t('general.citySuburb') }}
                />
                <Input
                  type="text"
                  label={t('general.stateTerritory')}
                  name="state"
                  settings={{
                    placeholder: t('general.stateTerritory'),
                  }}
                />
              </div>
              <div className="flex gap-3">
                <Input
                  type="text"
                  label={t('general.postCode')}
                  name="postcode"
                  settings={{
                    id: 'postcode',
                    placeholder: t('general.postCode'),
                  }}
                />
              </div>
              <div className="sticky bottom-0 grid grid-cols-2 gap-5 bg-white pt-4">
                <Button
                  kind="primary"
                  type="submit"
                  children={t('general.save')}
                />
                <Button kind="red" onClick={handleClose}>
                  {t('general.cancel')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
