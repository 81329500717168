import { useEffect, useState } from 'react';
import {
  DTRepairer_Public,
  FBD_Repairer_Private,
  FBD_Repairer_Public,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import { useGetMySagePersonas } from '@rabbit/data/portal';

import { useTranslation } from 'react-i18next';
import { checkIfAllowedEmailForUser } from '../../shared/helpers';

/**
 * A custom hook to retrieve installer peers and their loading state.
 *
 * @return {Object} An object containing the following properties:
 * - `installerPeer`: An array of installer peers of type `DTRepairer_Public`.
 * - `installerPeerLoading`: A boolean indicating the loading state of the installer peers.
 */
export function useGetInstallerPeers() {
  const [installerPeerLoading, setInstallerPeerLoading] = useState(true);
  const [installerPeer, setInstallerPeer] = useState<DTRepairer_Public[]>([]);
  const { installerPersona, repairerPersona } = useGetMySagePersonas();
  const { t } = useTranslation();
  useEffect(() => {
    const filterPeers = async (): Promise<DTRepairer_Public[]> => {
      try {
        const fetchInstaller = await FBD_Repairer_Private.get(
          `${PersonaTypeSingleLetter.Installer}:${t('tenantLink')}`
        );
        const idArr = Object.keys(fetchInstaller?.delegates ?? {}).filter(
          (id) =>
            id !== `${PersonaTypeSingleLetter.Installer}:${t('tenantLink')}`
        );
        const promises = idArr.map((id) => FBD_Repairer_Public.get(id));
        const peerData = await Promise.all(promises);
        const environment: string = import.meta.env.VITE_FIREBASE_MODE;

        return peerData.filter(
          (item): item is DTRepairer_Public =>
            !!item && !checkIfAllowedEmailForUser(item.email!, environment)
        );
      } catch (error) {
        console.error('Error filtering peers:', error);
        return [];
      }
    };

    const fetchPeers = async (): Promise<void> => {
      try {
        const filteredPeers = await filterPeers();
        setInstallerPeer(filteredPeers);
      } catch (error) {
        console.error('Error fetching peers:', error);
      } finally {
        setInstallerPeerLoading(false);
      }
    };

    fetchPeers();
  }, [
    repairerPersona?.repairer_private?.principals?.[0],
    installerPersona?.personaId,
  ]);

  return { installerPeer, installerPeerLoading };
}
