import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  formatPhoneNumber,
  formatUnixTime,
  getCurrencyFormat,
  Heading,
  LabelledItem,
  LabelledItemList,
  LoadingSpinner,
  renderCountryFormattedAddress,
  renderShortFormattedAddress,
  renderStateFormattedAddress,
  UploadedFileCard,
} from '@rabbit/elements/shared-components';
import { t } from 'i18next';
import {
  FBD_Consumer_Private,
  FBD_Holding_Private,
  UserUploadedDocument,
} from '@rabbit/data/types';
import { UploadedFileCategories } from '@rabbit/elements/shared-types';
import {
  useSageAPI,
  FileStorageContext,
  VendableEditorContext,
} from '@rabbit/bizproc/react';
import { OurDateTime } from '@rabbit/utils/ts';
import { AppContext } from '@rabbit/app-context';
import { toast } from 'react-toastify';
import { isArray } from 'class-validator';
import { getUnixTime } from 'date-fns';
import { GetVendableByDocId } from '@rabbit/search/cherchons';
import placeholderImageSrc from '../../../../assets/images/placeholder-image.png';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { REGISTRATION_FIELDS } from '@rabbit/config/enums';
import { formatDate } from '@rabbit/sage/utils/helpers';
import { CORPORATE } from '@rabbit/sage/utils/consts.ts';
import Divider from '@mui/material/Divider';
import { AdditionalFields } from '@rabbit/helper';

export interface SubmissionPreviewFormProps {
  onHoldingCreated: (result: any) => void;
  handleClose: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onChange: (step: number, data?: any) => void;
  data: any;
  children?: React.ReactNode;
  kind?: string;
}

export interface SummaryProps {
  label: string;
  required?: boolean;
  value: string | any;
}

const DefaultTenantNewRegistrationSummary = ({
  onHoldingCreated,
  handleClose,
  setLoading,
  onChange,
  data,
  children,
}: SubmissionPreviewFormProps) => {
  const { config } = useContext(AppContext) || {};
  const { previewData } = data || {};
  const holdingFormRef = useRef<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { registerConsumerHoldingSage } = useSageAPI();
  const [expand, setExpand] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<Record<string, any>>({
    product: [],
    productImage: '',
    productSku: '',
    productName: '',
    registration: [],
    consumer: [],
    primaryContact: [],
    secondaryContact: [],
    additionalData: [],
  });

  console.log('data', data);

  const contextValues = useContext(VendableEditorContext);
  const { getWarrantyTemplates } = contextValues;

  const {
    uploadQueueState,
    moveCompletedUploadsToAttached,
    updateHoldingWithFiles,
  } = useContext(FileStorageContext) || {};
  const { completed } = uploadQueueState || {};
  const [productLoading, setProductLoading] = useState<boolean>(false);

  const holdingPrivate = FBD_Holding_Private.empty();
  const holdingDocId = config?.CLAIMS?.CLAIMS_FLOW?.SERIAL_NUMBER_PROOF
    ? FBD_Holding_Private.keygen(holdingPrivate, FBD_Holding_Private)
    : '';

  const consumerPrivate = FBD_Consumer_Private.empty();
  const consumerDocId = config?.CLAIMS?.CLAIMS_FLOW?.SERIAL_NUMBER_PROOF
    ? FBD_Consumer_Private.keygen(consumerPrivate, FBD_Consumer_Private)
    : '';

  const newConsumerLink = data?.consumer?.id ?? consumerDocId;

  const {
    consumer,
    product,
    values,
    newUser,
    serialNumberProofFiles,
    location,
    proofOfPurchaseFile,
  } = previewData || {};

  // Handle purchase location logic
  const purchaseLocation =
    values.custom_store_name && values.store_not_listed
      ? null
      : values.purchase_location?.docid || values.purchase_location || '';

  const purchaseLocationOther = values.store_not_listed
    ? values.custom_store_name ?? ''
    : null;

  const purchaseCountry =
    values.custom_store_name && values.store_not_listed
      ? values.purchase_country
      : values.purchase_location?.country ?? '';

  const getLocation = () => {
    if (values.store_not_listed) {
      return purchaseLocationOther
        ? `${purchaseLocationOther} ${purchaseCountry ?? ''}`.trim()
        : '-';
    }
    return purchaseLocation ? location.label : '-';
  };

  const getProductData = async () => {
    setProductLoading(true);
    try {
      const warrantyTemplates = await getWarrantyTemplates();
      // Fetch product data using the provided holding vendable ID
      const vendableData = await GetVendableByDocId(
        values?.holding_vendable_id
      );
      const productData = vendableData[0];
      const warrantyPlan = warrantyTemplates.find(
        (template) => template.docid === productData.defaultTemplate
      )?.title;

      // Construct summary of product details
      const summaryProductData = [
        { label: t('general.productName'), value: productData?.title || '-' },
        { label: t('general.brand'), value: productData?.brand || '-' },
        {
          label: t('general.category'),
          value:
            productData?.category?.slice(-1)[0]?.split('-').join(' ') ||
            '' ||
            '-',
        },
        { label: t('SKU'), value: productData?.mpn || '-' },
        { label: t('UPC'), value: productData?.upc || '-' },
        {
          label: t('general.warrantyPlan'),
          value: warrantyPlan || '-',
        },
        ...(config?.VENDABLES.CUSTOM_FIELDS.DEPRECATED
          ? [
              {
                label: t('general.deprecated'),
                value: productData?.deprecated ? 'Yes' : 'No',
              },
            ]
          : []),

        // { label: t('general.details'), value: productData?.detail || '-' },
      ];

      // Construct summary of registration details
      const summaryRegistrationData = [
        {
          label: t('general.purchaseDate'),
          required: false,
          value: values?.purchase_time
            ? formatUnixTime(
                getUnixTime(new Date(values.purchase_time)),
                'dd/MM/yyyy'
              )
            : '-',
        },
        ...(config?.HOLDINGS.MANDATORY_FIELDS.PURCHASE_PRICE
          ? [
              {
                label: t('general.purchasePrice'),
                required: true,
                value: values?.purchase_price
                  ? getCurrencyFormat(
                      values.purchase_price.amount,
                      values.purchase_price.currency
                    )
                  : '-',
              },
            ]
          : []),

        {
          label: t('general.store'),
          required: true,
          value: getLocation(),
        },
        ...(values?.serial_number && [
          {
            label: t('general.serialNumber'),
            required: true,
            value: values?.serial_number || '-',
          },
        ]),
        ...(config?.HOLDINGS.NEW_REGISTRATION_FLOW.EXTRA_FIELDS.includes(
          REGISTRATION_FIELDS.CHASSIS_NUMBER
        )
          ? [
              {
                label: t('general.chassis_number'),
                required: true,
                value: values?.chassis_number || '-',
              },
            ]
          : []),
        ...(config?.HOLDINGS.NEW_REGISTRATION_FLOW.EXTRA_FIELDS.includes(
          REGISTRATION_FIELDS.REGISTRATION_NUMBER
        )
          ? [
              {
                label: t('general.registration_number'),
                required: true,
                value: values?.registration_number || '-',
              },
            ]
          : []),
        ,
        ...(config?.HOLDINGS.NEW_REGISTRATION_FLOW.EXTRA_FIELDS.includes(
          REGISTRATION_FIELDS.PRODUCTION_DATE
        )
          ? [
              {
                label: t('general.production_date'),
                required: true,
                value: formatDate(new Date(values?.production_date)) || '-',
              },
            ]
          : []),
        ...(config?.HOLDINGS.NEW_REGISTRATION_FLOW.EXTRA_FIELDS.includes(
          REGISTRATION_FIELDS.MILEAGE
        )
          ? [
              {
                label: t('general.mileage'),
                required: true,
                value:
                  `${Number(values?.mileage.value).toLocaleString('en')} ${
                    values?.mileage.addon ?? values?.mileage.unit
                  }` || '-',
              },
            ]
          : []),
      ];

      const additionalFieldData = [];
      if (config.HOLDINGS.NEW_REGISTRATION_FLOW.ADDITIONAL_FIELDS) {
        const additionalFields: AdditionalFields =
          config.HOLDINGS.NEW_REGISTRATION_FLOW.ADDITIONAL_FIELDS;

        for (const key in additionalFields) {
          if (
            Object.prototype.hasOwnProperty.call(
              values as Record<string, any>,
              key
            )
          ) {
            additionalFieldData.push({
              label: additionalFields[key].title,
              value:
                typeof values[key] === 'boolean'
                  ? values[key]
                    ? 'Yes'
                    : 'No'
                  : values[key],
            });
          }
        }
      }

      // Extract consumer address
      /*const customerAddress = newUser?.consumer_address
        ? [
            newUser.consumer_address.postcode,
            newUser.consumer_address.line1,
            newUser.consumer_address.line2,
            newUser.consumer_address.town,
            newUser.consumer_address.state,
            newUser.consumer_address.country,
          ]
            .filter(Boolean) // Removes empty values
            .join(', ') // Joins non-empty parts with a comma
        : '-';*/

      const customerAddress = renderShortFormattedAddress(
        newUser?.consumer_address || consumer?.item?.address[0] || ''
      );

      const cityAddress = renderStateFormattedAddress(
        newUser?.consumer_address || consumer?.item?.address[0] || ''
      );
      const countryAddress = renderCountryFormattedAddress(
        newUser?.consumer_address ?? consumer?.item?.address[0] ?? ''
      );

      const consumerDetails = [
        { label: t('general.address'), value: customerAddress },
        { label: t('general.cityAndState'), value: cityAddress },
        { label: t('general.country'), value: countryAddress },
      ];

      const primaryContact = [];
      const secondaryContact = [];
      if (data.type && data.type === CORPORATE) {
        consumerDetails.unshift(
          {
            label: t('general.businessName'),
            value: consumer?.item?.business_name || data?.business_name || '-',
          },
          {
            label: t('general.taxId'),
            value: consumer?.item?.tax_id || newUser?.tax_id || '-',
          }
        );
        primaryContact.push(
          {
            label: t('general.name'),
            value:
              consumer?.item?.fullname ||
              `${newUser?.consumer_first_name} ${newUser?.consumer_last_name}` ||
              '-',
          },

          {
            label: t('general.email'),
            value: newUser?.consumer_email || consumer?.value || '-',
          },
          {
            label: t('general.phoneNumber'),
            value: newUser?.consumer_telephone
              ? `${formatPhoneNumber(newUser?.consumer_telephone)}`
              : `${formatPhoneNumber(consumer?.item.phone)}` ?? '',
          }
        );
        secondaryContact.push(
          {
            label: t('general.name'),
            value: newUser?.secondary_first_name
              ? `${newUser?.secondary_first_name} ${newUser?.secondary_last_name}`
              : consumer?.item?.secondary_first_name
              ? `${consumer?.item?.secondary_first_name} ${consumer?.item?.secondary_last_name}`
              : '-',
          },
          {
            label: t('general.email'),
            value:
              newUser?.secondary_email || consumer?.item.secondary_email || '-',
          },
          {
            label: t('general.phoneNumber'),
            value: newUser?.consumer_telephone
              ? `${formatPhoneNumber(newUser?.secondary_phone_number)}`
              : `${formatPhoneNumber(consumer?.item.secondary_phone_number)}` ??
                '',
          }
        );
      } else {
        consumerDetails.unshift(
          {
            label: t('general.name'),
            value:
              consumer?.item?.fullname ||
              `${newUser?.consumer_first_name} ${newUser?.consumer_last_name}` ||
              '-',
          },
          {
            label: t('general.email'),
            value: newUser?.consumer_email || consumer?.value || '-',
          },
          {
            label: t('general.phoneNumber'),
            value: newUser?.consumer_telephone
              ? `${formatPhoneNumber(newUser?.consumer_telephone)}`
              : `${formatPhoneNumber(consumer?.item.phone)}` ?? '',
          }
        );
      }

      // Set summary data for product and consumer
      setSummaryData({
        product: summaryProductData,
        productImage: productData?.img?.[0] || placeholderImageSrc,
        productSku: productData?.mpn || '-',
        productName:
          `${productData?.full} (${
            productData?.category?.slice(-1)[0]?.split('-').join(' ') || ''
          })` || '-',
        consumer: consumerDetails,
        primaryContact,
        secondaryContact,
        registration: summaryRegistrationData,
        registrationSerialNumberProofFile: {
          label: t('general.proofOfSerialNumber'),
          value: serialNumberProofFiles,
        },
        registrationProofOfPurchaseFile: {
          label: t('general.proofOfPurchase'),
          value: proofOfPurchaseFile,
        },
        additionalData: additionalFieldData,
      });
      setProductLoading(false);
    } catch (error) {
      console.error('Error fetching product data:', error);
      setProductLoading(false);
    } finally {
      setProductLoading(false);
    }
  };

  useEffect(() => {
    getProductData();
  }, [values?.holding_vendable_id]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setLoading(true);

    const finalData = {
      ...(data.holding
        ? {
            // Existing holding data
            consumer_first_name: data.consumer.splitname?.first,
            consumer_last_name: data.consumer.splitname?.last,
            consumer_address: data.consumer.address,
            consumer_email: data.consumer.email,
            consumer_telephone: data.consumer.phone,
            consumer_preferred_contact: data.consumer.preferred_contact,
            holding_vendable_id: data.holding.vendable,
            purchase_location: purchaseLocation,
            purchase_location_other: purchaseLocationOther,
            purchase_price: {
              amount: Number(values.purchase_price.amount),
              currency: values.purchase_price.currency,
            },
            purchase_time: values.purchase_time
              ? OurDateTime.dateToTimestamp(new Date(values.purchase_time))
              : 0,
            purchase_country: purchaseCountry,
            consumer_is_commercial: data.consumer_is_commercial,
            serial: values?.serial_number ?? '',
            serial_number_proof: serialNumberProofFiles ?? [],
            purchase_proof: proofOfPurchaseFile || [],
            baseUrl: window.location.origin,
            additionalData: data.additionalData,
          }
        : {
            // New holding data
            ...data,
            ...values,
            purchase_date: values.purchase_time
              ? OurDateTime.dateToTimestamp(new Date(values.purchase_time))
              : null,
            consumer_holding_purchase_location: purchaseLocation,
            consumer_holding_purchase_country: purchaseCountry,
            consumer_holding_purchase_location_other: purchaseLocationOther,
            serial: values?.serial_number ?? '',
            serial_number_proof: serialNumberProofFiles ?? [],
            purchase_proof: proofOfPurchaseFile || [],
            baseUrl: window.location.origin,
          }),
    };

    try {
      const response = await registerConsumerHoldingSage({
        formData: finalData,
        tenantLink: t('tenantLink'),
        newHoldingLink: previewData?.holding_docid || holdingDocId,
        newConsumerLink: newConsumerLink,
      });

      if (response) {
        // Handle file uploads if required
        if (config?.CLAIMS?.CLAIMS_FLOW?.SERIAL_NUMBER_PROOF) {
          if (
            moveCompletedUploadsToAttached &&
            updateHoldingWithFiles &&
            serialNumberProofFiles?.length > 0
          ) {
            await updateHoldingWithFiles(
              previewData?.holding_docid || holdingDocId,
              serialNumberProofFiles as UserUploadedDocument[],
              UploadedFileCategories.SerialNumberProof
            );
            moveCompletedUploadsToAttached(serialNumberProofFiles);
          }
        }

        if (config?.HOLDINGS.NEW_REGISTRATION_FLOW.PROOF_OF_PURCHASE) {
          if (
            moveCompletedUploadsToAttached &&
            updateHoldingWithFiles &&
            proofOfPurchaseFile &&
            proofOfPurchaseFile.length > 0
          ) {
            await updateHoldingWithFiles(
              previewData?.holding_docid || holdingDocId,
              proofOfPurchaseFile as UserUploadedDocument[],
              UploadedFileCategories.ConsumerProofPurchase
            );
            moveCompletedUploadsToAttached(proofOfPurchaseFile);
          }
        }

        onHoldingCreated({
          consumerLink: response.consumerLink,
          holdingLink: response.holdingLink,
          warranty: response.warranty,
        });

        handleClose();
      } else {
        throw new Error(t('general.failedToRegisterHolding'));
      }
    } catch (err: any) {
      console.error(err);
      toast.error(
        err?.message || t('general.somethingWentWrongPleaseTryAgain')
      );
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  return children ? (
    children
  ) : (
    <div className="h-auto w-full space-y-3 p-5 text-base">
      {productLoading ? (
        <div className={`h-28 w-full`}>
          <LoadingSpinner overlay={true} size="sm" />
        </div>
      ) : (
        <div className="sticky bottom-0 space-y-5 bg-white">
          <div>
            <Heading kind="h4" className="mb-2">
              {t('general.productDetails')}
            </Heading>
            <div
              className={
                'font-nunito space-y-3 rounded-md border border-gray-300 p-4'
              }
            >
              <div className="grid w-full grid-cols-[_1fr,40px]">
                <div
                  className={`grid w-full cursor-pointer grid-cols-[75px,_1fr] gap-4`}
                  onClick={() => setExpand(!expand)}
                >
                  <img
                    src={summaryData?.productImage}
                    className={`h-[75px] w-[75px] overflow-hidden rounded-md border-[1px] border-gray-300 object-cover`}
                  />
                  <div
                    className={`grid h-auto w-full grid-cols-1 place-content-start gap-1`}
                  >
                    <h3 className={`text-[16px] font-[700] capitalize`}>
                      {summaryData.productName}
                    </h3>
                    <span className={`text-[14px] font-[500] text-[#374151]`}>
                      {summaryData.productSku}
                    </span>
                  </div>
                </div>
                <ChevronDownIcon
                  onClick={() => setExpand(!expand)}
                  className={
                    'relative h-6 w-6 cursor-pointer text-gray-900 transition-all duration-300' +
                    (expand ? ' rotate-180' : '')
                  }
                />
              </div>
              {expand && (
                <div className={`grid h-auto w-full grid-cols-3 gap-5`}>
                  {summaryData?.product?.map(
                    ({ label, value }: SummaryProps, index: number) => {
                      return (
                        <DefaultTenantNewRegistrationSummaryContent
                          key={index + label}
                          label={label}
                          value={value}
                        />
                      );
                    }
                  )}
                </div>
              )}
            </div>
          </div>

          <div>
            <Heading kind="h4" className="mb-1">
              {t('general.registrationDetails')}
            </Heading>
            <div
              className={
                'font-nunito space-y-3 rounded-md border border-gray-300 p-4'
              }
            >
              <div className={`grid h-auto w-full grid-cols-3 gap-5`}>
                {summaryData?.registration?.map(
                  ({ label, value }: SummaryProps, index: number) => {
                    return (
                      <DefaultTenantNewRegistrationSummaryContent
                        key={index + label}
                        label={label?.split('_')?.join(' ')}
                        value={value}
                      />
                    );
                  }
                )}
                {summaryData.additionalData.map(
                  (items: { label: string; value: string }) => {
                    return (
                      <LabelledItem label={items.label} value={items.value} />
                    );
                  }
                )}
                <div className={`col-span-full`}>
                  <DefaultTenantNewRegistrationSummaryContent
                    label={
                      summaryData?.registrationSerialNumberProofFile?.label
                    }
                    value={
                      summaryData?.registrationSerialNumberProofFile?.value
                    }
                  />
                </div>
                <div className={`col-span-full`}>
                  <DefaultTenantNewRegistrationSummaryContent
                    label={summaryData?.registrationProofOfPurchaseFile?.label}
                    value={summaryData?.registrationProofOfPurchaseFile?.value}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <Heading kind="h4" className="mb-1">
              {t('general.customerDetails')}
            </Heading>
            <div className="font-nunito flex flex-col space-y-3 rounded-md border border-gray-300 p-4">
              <div className="text-[16px] font-[700] text-black">
                {summaryData?.consumer?.business_name ??
                  summaryData?.consumer?.fullname}
              </div>
              {summaryData?.consumer?.business_name && (
                <div className={`space-y-1`}>
                  {summaryData?.consumer?.fullname}
                </div>
              )}
              <div className="w-full">
                <div className="font-nunito mb-5">
                  <LabelledItemList
                    items={summaryData?.consumer}
                    itemProps={{
                      labelClasses:
                        'font-nunito mb-[10px] text-sm text-gray-500',
                      valueClasses: 'font-nunito font-medium',
                    }}
                    listWrapperClasses={`font-nunito grid grid-cols-3 gap-y-6 gap-x-4`}
                  />
                </div>

                {summaryData?.primaryContact.length > 0 && (
                  <>
                    <Divider />
                    <p className="mb-5 mt-5 w-full overflow-hidden text-ellipsis text-base opacity-70">
                      {t('general.primaryContact')}
                    </p>
                    <div className="font-nunito mb-5">
                      <LabelledItemList
                        items={summaryData?.primaryContact}
                        itemProps={{
                          labelClasses:
                            'font-nunito mb-[10px] text-sm text-gray-500',
                          valueClasses: 'font-nunito font-medium',
                        }}
                        listWrapperClasses={`font-nunito grid ${'grid-cols-3'} gap-y-6 gap-x-4`}
                      />
                    </div>
                    <Divider />
                    <p className="mb-5 mt-5 w-full overflow-hidden text-ellipsis text-base opacity-70">
                      {t('general.secondaryContact')}
                    </p>
                    <div className="font-nunito mb-5">
                      <LabelledItemList
                        items={summaryData?.secondaryContact}
                        itemProps={{
                          labelClasses:
                            'font-nunito mb-[10px] text-sm text-gray-500',
                          valueClasses: 'font-nunito font-medium',
                        }}
                        listWrapperClasses={`font-nunito grid ${'grid-cols-3'} gap-y-6 gap-x-4`}
                      />
                    </div>
                  </>
                )}

                {/*{Object.keys(summaryData?.consumer).map(
                  (items: string, index: number) => {
                    return (
                      items !== 'fullname' &&
                      items !== 'business_name' &&
                      summaryData?.consumer[items] !== '-' && (
                        <div
                          className={`flex h-auto w-full items-start justify-start gap-2`}
                          key={index + items}
                        >
                           <span
                            className={`text-[16px] font-[500] capitalize text-[#6B7280]`}
                          >
                            {items.split('_').join(' ')}:
                          </span>
                          <Heading
                            kind="p"
                            className={`text-[16px] font-[500] text-black`}
                          >
                            {summaryData?.consumer[items]}
                          </Heading>
                        </div>
                      )
                    );
                  }
                )}*/}
                {/* <Heading kind="p">{summaryData?.consumer?.address}</Heading>
              <Heading kind="p">+{summaryData?.consumer?.phone_number}</Heading> */}
              </div>
            </div>
          </div>

          <div
            className={`sticky bottom-0 grid h-auto w-full grid-cols-2 gap-5`}
          >
            <Button
              kind="primary"
              loading={isSubmitting}
              className="w-full"
              onClick={handleSubmit}
            >
              {t('general.submit')}
            </Button>
            <Button kind="red" type="submit" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DefaultTenantNewRegistrationSummary;

export const DefaultTenantNewRegistrationSummaryContent = ({
  label,
  required,
  value,
}: SummaryProps) => {
  if (value?.length === 0) return;
  return (
    <div className="grid h-auto w-auto min-w-[150px] grid-cols-1 place-content-start gap-2">
      <span className="text-[12px] font-[500] capitalize text-[#6B7280]">
        {label}
      </span>
      {isArray(value) ? (
        value?.map((items: UserUploadedDocument, index: number) => (
          <UploadedFileCard
            key={index + items.version}
            data={items}
            onDeleteFile={undefined}
          />
        ))
      ) : (
        <span className={`truncate text-[16px] font-[500] capitalize`}>
          {value}
        </span>
      )}
    </div>
  );
};
