import { Button, Heading, Input } from '@rabbit/elements/shared-components';
import { Form, Formik } from 'formik';
import { LIST_COUNTRIES } from '@rabbit/bizproc/react';
import { SageFileUploader } from '@rabbit/sage/components/organisms/upload-wrapper/SageFileUploader.tsx';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useQueryParams } from 'raviger';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UserUploadedDocument } from '@rabbit/data/types';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { AppContext } from '@rabbit/app-context';
import {
  countryDateFormats,
  countryDateLocalesString,
} from '@rabbit/sage/utils/consts.ts';

interface HoldingDataCase {
  holding_vendable_id: string;
  purchase_time: Date | null;
  purchase_price: {
    amount: number;
    currency: string;
  };
  purchase_location: {
    docid: string;
    country: string;
  } | null;
  store_not_listed: boolean;
  custom_store_name: string;
  purchase_country: string;
  serial: string;
  consumer_proof_of_purchase: UserUploadedDocument[];
  installation_attachment: UserUploadedDocument[];
  installer_id: string;
  installation_date: string;
  installation_time: string;
}

interface ProductDetailsViewProps {
  steps: string[];
  setCurrentStep: Dispatch<SetStateAction<any>>;
  defaultSelected: any;
  onSubmitProductData: (values: HoldingDataCase) => Promise<void>;
  holding_docid: string;
  newConsumerLink: string;
}

export default function ProductDetailsView({
  steps,
  setCurrentStep,
  defaultSelected,
  onSubmitProductData,
  holding_docid,
  newConsumerLink,
}: ProductDetailsViewProps) {
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const params = useQueryParams();
  const formikRefProduct = useRef(null) as any;

  const serialNumber = params[0] && params[0].sn ? params[0].sn : '';

  const [isDatePurchaseDisabled, setIsDatePurchaseDisabled] = useState(false);

  const registrationInitialValues: HoldingDataCase = {
    holding_vendable_id: defaultSelected?.id ?? '',
    purchase_time: null,
    purchase_price: {
      amount: 0,
      currency: tenantInfo?.currency ?? 'GBP',
    },
    purchase_location: null,
    store_not_listed: false,
    custom_store_name: '',
    purchase_country: tenantInfo?.country ?? '',
    serial: serialNumber,
    consumer_proof_of_purchase: [],
    installation_attachment: [],
    installer_id: '',
    installation_date: '',
    installation_time: '',
  };

  const validationSchema = Yup.object().shape({
    holding_vendable_id: Yup.string()
      .trim()
      .required(t('general.productNameCantBeEmpty')),
    purchase_location: Yup.mixed(),
    store_not_listed: Yup.boolean(),
    purchase_price: Yup.object({
      amount: Yup.number()
        .min(1, t('message.pleaseEnterValidAmount'))
        .required(t('message.pleaseEnterValidAmount')),
      currency: Yup.string().trim().required(t('message.currencyIsRequired')),
    })
      .required(t('message.pleaseEnterPurchasePrice'))
      .typeError(t('message.pleaseEnterPurchasePrice')),
    purchase_time: Yup.string().nullable().trim(),
    installation_date: Yup.string()
      .trim()
      .required(t('message.installationDateRequired')),
    installation_time: Yup.string()
      .trim()
      .required(t('message.installationTimeRequired')),
  });

  const { config } = useContext(AppContext);

  const backToCustomer = () => void setCurrentStep(steps[1]);

  useEffect(() => {
    if (
      formikRefProduct &&
      formikRefProduct.current &&
      isDatePurchaseDisabled
    ) {
      formikRefProduct.current.setFieldValue('purchase_time', null);
    }
  }, [isDatePurchaseDisabled]);
  // Ensure the formik reference exists and purchase date is disabled
  if (formikRefProduct?.current && isDatePurchaseDisabled) {
    // Update the purchase_time field to null when the date of purchase is disabled
    formikRefProduct.current.setFieldValue('purchase_time', null);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Formik
        initialValues={registrationInitialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitProductData}
        validateOnChange={true}
        validateOnBlur={false}
        innerRef={formikRefProduct}
      >
        {({ errors, values, setFieldValue, isValid }) => (
          <Form className="flex-col gap-3">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:p-2">
              <Input
                type="autocomplete-vendable"
                name="holding_vendable_id"
                label={`${t('general.product')}*`}
                settings={{
                  isMulti: false,
                  id: 'holding_vendable_id',
                  placeholder: t('general.productName'),
                  options: [],
                  hint: t('*required'),
                  tenantLink: t('tenantLink'),
                  disabled: !!defaultSelected,
                  value: defaultSelected,
                }}
              />
              <div className="flex flex-col gap-2">
                <Input
                  type="datepicker"
                  name="purchase_time"
                  label={t('general.dateOfPurchase')}
                  settings={{
                    id: 'purchase_time',
                    maxDate: new Date(),
                    placeholder:
                      tenantInfo?.country &&
                      countryDateFormats[tenantInfo?.country]
                        ? countryDateFormats[tenantInfo?.country].toUpperCase()
                        : 'DD/MM/YYYY',
                    locale:
                      (tenantInfo?.country &&
                        countryDateLocalesString[tenantInfo?.country]) ??
                      'en-GB',
                    disabled: isDatePurchaseDisabled,
                  }}
                />
                <div className="flex items-center pt-2">
                  <Input
                    type="checkbox"
                    name="noDateOfPurchase"
                    settings={{
                      checkboxLabel: t('general.dateNotKnown'),
                      checkboxLabelStyles: 'text-base text-gray-500',
                      asBoolean: true,
                      onChange: () =>
                        setIsDatePurchaseDisabled(!isDatePurchaseDisabled),
                    }}
                  />
                </div>
                <Input
                  type="datepicker"
                  name="installation_date"
                  label={t('general.installationDate')}
                  settings={{
                    id: 'installation_date',
                    placeholder:
                      tenantInfo?.country &&
                      countryDateFormats[tenantInfo?.country]
                        ? countryDateFormats[tenantInfo?.country].toUpperCase()
                        : 'DD/MM/YYYY',
                    locale:
                      (tenantInfo?.country &&
                        countryDateLocalesString[tenantInfo?.country]) ??
                      'en-GB',
                    maxDate: new Date(),
                    initialDate: new Date(),
                    hint: '*required',
                  }}
                />
                <Input
                  type="time"
                  name="installation_time"
                  label={t('general.installationTime')}
                  settings={{
                    id: 'installation_time',
                    placeholder: t('general.installationTime'),
                    hint: '*required',
                  }}
                />
                <Input
                  type="checkbox"
                  name="noDateOfPurchase"
                  settings={{
                    checkboxLabel: t('general.dateNotKnown'),
                    checkboxLabelStyles: 'text-base text-gray-500',
                  }}
                />
              </div>
              <Input
                type="datepicker"
                name="installation_date"
                label={t('general.installationDate') + '*'}
                settings={{
                  id: 'installation_date',
                  placeholder: 'DD/MM/YYYY',
                  minDate: new Date(),
                  initialDate: new Date(),
                  hint: '*required',
                }}
              />
              <Input
                type="time"
                name="installation_time"
                label={t('general.installationTime') + '*'}
                settings={{
                  id: 'installation_time',
                  placeholder: t('general.installationTime'),
                  hint: '*required',
                }}
              />
              <Input
                type="currency"
                name="purchase_price"
                label={`${t('general.purchasePrice')}`}
                settings={{
                  id: 'purchase_price',
                  placeholder: t('general.purchaseAmount'),
                  currency: tenantInfo?.currency,
                }}
              />
              <div className="flex grow flex-col gap-2">
                <Input
                  type="autocomplete-location-retailer"
                  name="purchase_location"
                  label={t('general.storeOfPurchase')}
                  settings={{
                    isMulti: false,
                    placeholder: t('message.pleaseSelectAnOption'),
                    options: [],
                    errors: errors,
                    disabled: values.store_not_listed,
                  }}
                />
                <Input
                  type="checkbox"
                  name="store_not_listed"
                  settings={{
                    checkboxLabel: t('general.storeIsNotListed'),
                    checkboxLabelStyles: 'text-base text-gray-500',
                  }}
                />
                {values.store_not_listed && (
                  <div className="mt-2 flex flex-col gap-2">
                    <Input
                      type="text"
                      name="custom_store_name"
                      settings={{
                        id: 'custom_store_name',
                        placeholder: t('general.enterStoreName'),
                      }}
                    />
                    <Input
                      type="select"
                      label=""
                      name="purchase_country"
                      settings={{
                        options: LIST_COUNTRIES,
                        id: 'purchase_country',
                        placeholder: t(
                          'general.countryWhereProductWasPurchased'
                        ),
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="md:col-span-2">
                <Input
                  type="text"
                  label={t('general.serialNumber')}
                  name="serial"
                  settings={{
                    placeholder: t('general.serialNumber'),
                    disabled: serialNumber !== '',
                  }}
                />

                <div className="flex flex-col md:col-span-2">
                  <SageFileUploader
                    label={t('general.proofOfPurchase')}
                    identifiers={{
                      category: UploadedFileCategories.ConsumerProofPurchase,
                      docType: {
                        docid: holding_docid,
                        type: DocTypeShapeTypes.Holding,
                      },
                      personaId: newConsumerLink,
                    }}
                    onUploadCompleted={(files) =>
                      setFieldValue('consumer_proof_of_purchase', files)
                    }
                    onDeleteFile={() =>
                      setFieldValue('consumer_proof_of_purchase', [])
                    }
                    accepts={['image/*', '.pdf']}
                    shouldAutoUpdateDocs={false}
                    currentFiles={values?.consumer_proof_of_purchase ?? []}
                  />
                </div>
                <div className="flex flex-col md:col-span-2">
                  <SageFileUploader
                    label={t('general.InstallationPhotosSupportingMaterials')}
                    identifiers={{
                      category: UploadedFileCategories.installation_attachment,
                      docType: {
                        docid: holding_docid,
                        type: DocTypeShapeTypes.Holding,
                      },
                      personaId: newConsumerLink,
                    }}
                    onUploadCompleted={(files) =>
                      setFieldValue('installation_attachment', files)
                    }
                    onDeleteFile={() =>
                      setFieldValue('installation_attachment', [])
                    }
                    currentFiles={values.installation_attachment ?? []}
                    shouldAutoUpdateDocs={false}
                    accepts={['image/*', 'video/*', '.pdf']}
                  />
                </div>
                <div className="md:col-span-2">
                  <Input
                    type="rich-text"
                    label={t('general.internalComment')}
                    name={'internal_comment'}
                    settings={{
                      id: 'internal_comment',
                      allowSpecialCharacter: true,
                    }}
                  />
                </div>
              </div>
              <div className="mt-4 grid grid-cols-1 gap-4 md:col-span-2 md:grid-cols-2 md:p-2">
                <Button
                  kind="primary"
                  type="submit"
                  className="w-full"
                  loading={isSubmitting}
                  disabled={!isValid || isSubmitting}
                >
                  {t('general.submit')}
                </Button>
                <Button
                  kind="outline_red"
                  type="button"
                  className="w-full"
                  onClick={backToCustomer}
                >
                  {t('general.back')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
