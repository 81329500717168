import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  Modal,
  ModalSettingsShape,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { OptionShape, useGetInstallerPeers } from '@rabbit/bizproc/react';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import {
  DTHoldingProxy,
  FBD_Holding_Private,
  FBD_HoldingProxy,
  FBD_Repairer_Private,
  FBD_Repairer_Public,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import { toast } from 'react-toastify';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext.tsx';
import { AppContext } from '@rabbit/app-context';

//export interface AssignCaseInternalModalProps {}
interface FormValuesShape {
  installer_id: string;
}

const initialValues = {
  installer_id: '',
};

const validationSchema = Yup.object().shape({
  installer_id: Yup.string()
    .trim()
    .required(t('An user must be assigned in order to continue')),
});

export interface ModalAssignInstallerHoldingProps {
  handleClose: () => void;
  modalSettings: ModalSettingsShape;
  holding_proxy?: DTHoldingProxy | null;
  setInstallerId: Dispatch<SetStateAction<string>>;
  setInstallerLoaded: Dispatch<SetStateAction<boolean>>;
  installerId: string;
  type: string;
}

export function AssignCaseInternalModal({
  handleClose,
  modalSettings,
  holding_proxy,
  setInstallerId,
  setInstallerLoaded,
  installerId,
  type,
}: ModalAssignInstallerHoldingProps) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { installerPeer, installerPeerLoading } = useGetInstallerPeers();

  const { executeAction, moveSpotlight } = useContext(CaseflowContext) || {};

  const installerUsers: OptionShape[] = installerPeerLoading
    ? [
        {
          id: '-1',
          label: `${t('general.loading')}...`,
          value: 'Loading...',
        },
      ]
    : (installerPeer
        .map((installer, index) => {
          const label = installer?.name
            ? `${installer.name}${
                installer.first_name
                  ? ` - ${installer.first_name} ${installer.last_name}`
                  : ''
              }`
            : '';
          const value = installer?.docid || '';
          return {
            id: `${index}`,
            label,
            value,
          };
        })
        .filter(Boolean) as OptionShape[]);

  const [usersForAssignment, setUsersForAssignment] = useState(installerUsers);

  useEffect(() => {
    if (usersForAssignment !== installerUsers)
      setUsersForAssignment(installerUsers);
  }, [installerPeer]);

  const onSubmit = async (values: FormValuesShape) => {
    try {
      const { installer_id } = values;
      setIsSubmitting(true);
      if (type === 'HOLDING') {
        const holdingPrivateData = await FBD_Holding_Private.get(
          holding_proxy?.holdingLink!
        );
        const installer = await FBD_Repairer_Public.get(installer_id);
        if (holdingPrivateData) {
          if (holdingPrivateData.installation) {
            holdingPrivateData.installation.installer = installer;
          }
          holdingPrivateData.installer = installer_id;
          await FBD_Holding_Private.set(holdingPrivateData);
        }
      } else {
        const nameForId = usersForAssignment.filter(
          (user) => user.value === values.installer_id
        );
        moveSpotlight &&
          (await moveSpotlight(PersonaTypeSingleLetter.Installer));
        executeAction &&
          (await executeAction('assign_installer_case', {
            delegate_installer_id: installer_id!,
            delegate_installer_name: nameForId[0].label!,
          }));
      }
      setInstallerId(installer_id);
      setIsSubmitting(false);
      setInstallerLoaded(false);
      handleClose();
      toast.success(t('message.installerHasBeenUpdatedSuccessfully'));
    } catch (err) {
      console.log(err);
      toast.error(t('message.somethingWentWrongPleaseTryAgain'));
    }
  };

  return (
    <Modal
      settings={{
        title: t('general.assignInstaller'),
        headerBackground: true,
        handleClose: handleClose,
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[724px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <Input
              type="select"
              label={`${t('general.assignTo')}*`}
              name="installer_id"
              settings={{
                options: usersForAssignment,
                id: 'installer_id',
                placeholder: t('general.select'),
                hint: '*required',
              }}
            />
            <div className="mt-2 flex gap-8">
              <Button
                kind="primary"
                type="submit"
                loading={isSubmitting}
                disabled={values.installer_id === 'Loading...' ? true : false}
              >
                {t('general.assignInstaller')}
              </Button>
              <Button kind="outline_red" onClick={handleClose}>
                {t('general.cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default AssignCaseInternalModal;
