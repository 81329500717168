import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BreadCrumbs from '../../atoms/BreadCrumbs/BreadCrumbs';
import NavBar from '../../molecules/NavBar/NavBar';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { UserContext } from '../../../context/UserContext';
import { useTranslation } from 'react-i18next';

const isDemoEnv = import.meta.env.DEMO;

type LayoutProps = {
  children: React.ReactNode;
};

export function Layout({ children }: LayoutProps) {
  const location = useLocation();
  const { state } = location;
  const contextValues = useContext(UserContext);

  //TODO: remove Link and set back claimId
  const { docid, productName, link, title, warrantyId } = state || {};

  const [breadCrumbLabel, setBreadCrumbLabel] = useState('');
  const { t } = useTranslation();

  const titleMap = [
    { path: SAGE_ROUTE_NAME.DASHBOARD, title: t('general.dashboard') },
    { path: SAGE_ROUTE_NAME.CLAIMS, title: t('general.claims') },
    { path: SAGE_ROUTE_NAME.MY_CLAIMS, title: t('general.myClaims') },
    { path: SAGE_ROUTE_NAME.FAULTS, title: t('general.faults') },
    { path: SAGE_ROUTE_NAME.MANAGE, title: t('general.manage') },
    {
      path: SAGE_ROUTE_NAME.PRODUCTS,
      title: isDemoEnv ? t('general.productsAndParts') : t('general.products'),
    },
    {
      path: SAGE_ROUTE_NAME.PRODUCTS + '/' + docid,
      title: isDemoEnv
        ? `${t('general.productsAndParts')} ${
            productName ? `> ${productName}` : ''
          }`
        : `${t('general.products')} ${productName ? `> ${productName}` : ''}`,
    },
    { path: SAGE_ROUTE_NAME.REGISTRATIONS, title: t('general.registrations') },
    {
      path: SAGE_ROUTE_NAME.REGISTRATIONS + '/' + link, //check this value
      title: `${t('general.registrations')} > ${warrantyId} - ${title}`,
    },
    {
      path: SAGE_ROUTE_NAME.WARRANTY_OVERVIEW,
      title: t('general.warrantiesSummary'),
    },
    {
      path: SAGE_ROUTE_NAME.WARRANTY_OVERVIEW + '/' + link,
      title: `${t('general.warrantiesSummary')} > ${title}`,
    },
    {
      //TODO: remove Link and set back claimId
      path: SAGE_ROUTE_NAME.CLAIMS + '/' + link,
      title: `${t('general.claims')} > ${
        docid && productName ? `${productName} (${docid})` : ''
      }`,
    },
    {
      path: SAGE_ROUTE_NAME.MANAGE + '/customer/' + docid,
      title: `${t('general.manage')} > ${t(
        'general.customers'
      )} > ${productName}`,
    },
    { path: SAGE_ROUTE_NAME.MANAGE_ACCOUNT, title: t('general.manageAccount') },
    {
      path: SAGE_ROUTE_NAME.MY_ORGANIZATION,
      title: t('general.myOrganization'),
    },
    { path: SAGE_ROUTE_NAME.PLANS_AND_PRICES, title: 'Plans' },
    { path: SAGE_ROUTE_NAME.PRICING, title: 'Pricing' },
    { path: SAGE_ROUTE_NAME.USER_GROUP, title: t('general.userGroup') },
    // { path: ROUTE_NAME.TERMS_AND_CONDITIONS, title: 'Terms & Conditions' },
    // { path: ROUTE_NAME.PRIVACY_POLICY, title: 'Privacy Policy' },
    {
      path: SAGE_ROUTE_NAME.PLATFORM_SERVICES_AGREEMENT,
      title: t('general.platformServicesAgreement'),
    },
  ];

  useEffect(() => {
    const currTitle = titleMap.find((item) => item.path === location.pathname);
    if (currTitle && currTitle.title) {
      //const pageTitle = 'iWarranty Admin - ' + appInfo.name + ' - ' + currTitle.title;
      const pageTitle = 'iWarranty Admin - ' + currTitle.title;
      setBreadCrumbLabel(currTitle.title);
      document.title = pageTitle;
    }
  }, [location, t]);

  return (
    <div className="flex w-full flex-col">
      {location.pathname !== '/login' && <NavBar />}
      {contextValues?.user && breadCrumbLabel && state !== 'off' && (
        <BreadCrumbs title={breadCrumbLabel} />
      )}
      <div className="flex w-full flex-grow flex-col px-4 py-4 md:m-[0_auto] md:max-w-[1280px] md:scroll-px-28 md:py-8 md:px-8">
        {children}
      </div>
    </div>
  );
}

export default Layout;
