import { Link, useNavigate } from 'react-router-dom';
import {
  CardWrapperWithHeader,
  getCurrencyFormat,
  LabelledItem,
} from '@rabbit/elements/shared-components';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { useContext } from 'react';
import { CaseflowContext } from '../../../context/CaseflowContext';
import Skeleton from 'react-loading-skeleton';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import { TenantLinkFieldName } from '@rabbit/data/types';
import { t } from 'i18next';
import { AppContext } from '@rabbit/app-context';
import { VehicleInfo } from '@rabbit/data/types';
import { Config_ClaimDetailViewType } from '@rabbit/config/enums';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProductDetailsCardProps {}

export function ProductDetailsCard(props: ProductDetailsCardProps) {
  const navigate = useNavigate();
  const context = useContext(CaseflowContext);
  const tenantLink = t(TenantLinkFieldName);
  const { config } = useContext(AppContext);
  // TODO: full_purchase_location isn't available on holding proxy so holdingData has to be used for now. needs to be added to holdingProxy
  // you can get this for srvs with retailerAddresses on the warranties array, not sure about non-SRVs. Investigate - dc
  const { holdingData, holdingProxyData } = context || {};

  //if (!context || !holdingData) return <LoadingSpinner size="xs" />;

  function getPurchaseLocation(
    holdingData: ConsumerHoldingSummaryShape
  ): string {
    let result = '-'; // Default value if no conditions are met
    const locationName = holdingData?.full_purchase_location?.name;
    const addresses = holdingData?.full_purchase_location?.addresses;
    const deprecatedAddress = holdingData?.full_purchase_location?.address;

    // Check the name and addresses array first
    if (locationName) {
      const town =
        addresses && addresses.length > 0 ? addresses[0]?.town || '' : '';
      if (town) {
        result = `${locationName} - ${town}`;
      } else {
        result = `${locationName}`;
      }
      return result;
    }

    // Fallback to the deprecated address property
    if (locationName && deprecatedAddress?.town) {
      result = `${locationName} - ${deprecatedAddress.town}`;
      return result;
    }

    // Fallback to the other purchase location if no addresses or deprecated address
    if (holdingData?.purchase_location_other) {
      result = holdingData?.purchase_location_other;
      return result;
    }
    return result;
  }

  const productName: string =
    (holdingData?.brand ?? '') + ' ' + (holdingData?.title ?? '');

  const srvProductInfo =
    config.CLAIMS.CLAIM_DETAIL_VIEW.TYPE ===
    Config_ClaimDetailViewType.CAR_DETAILS
      ? (holdingData?.srvInfo?.productInfo as VehicleInfo)
      : '';

  const onProductDetailsClick = () => {
    navigate(SAGE_ROUTE_NAME.REGISTRATIONS + '/' + holdingProxyData?.docid, {
      state: 'off',
    });
  };

  const isSRVTenant =
    tenantLink === 'NUCOVER' ||
    tenantLink === 'PINNACLEWARRANTIES' ||
    tenantLink === 'WARRANTYIRELAND';

  return (
    <CardWrapperWithHeader
      title={t('general.productDetails')}
      headerRight={
        isSRVTenant ? null : (
          <ArrowTopRightOnSquareIcon
            className="text-primary-900 h-5 w-5 cursor-pointer"
            onClick={onProductDetailsClick}
          />
        )
      }
    >
      {!holdingData ? (
        <div className="font-nunito flex flex-col gap-3">
          <Skeleton count={2} containerClassName="max-w-[150px]" />
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          {srvProductInfo && (
            <LabelledItem
              label={t('general.registrationNo')}
              value={srvProductInfo.registrationNo}
            />
          )}
          <LabelledItem label={t('general.productName')} value={productName} />
          <LabelledItem
            label={t('general.purchaseLocation')}
            value={getPurchaseLocation(holdingData)}
          />
          {holdingProxyData?.serial && (
            <LabelledItem
              label={t('general.productSerialNumber')}
              value={holdingProxyData.serial}
            />
          )}
          {typeof holdingData?.purchase_price !== 'string' &&
            !!holdingData?.purchase_price?.amount && (
              <LabelledItem
                label={t('general.purchasePrice')}
                value={getCurrencyFormat(
                  holdingData.purchase_price.amount,
                  holdingData.purchase_price.currency
                )}
              />
            )}
        </div>
      )}
    </CardWrapperWithHeader>
  );
}

export default ProductDetailsCard;
