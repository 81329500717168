import React from 'react';
import { AdditionalFields } from '@rabbit/helper';
import { CommonInputBuilder } from '../CommonFormBuilder/CommonInputBuilder';

interface DynamicFormBuilderProps {
  fields: AdditionalFields;
  maxGridCount?: number;
}

const sortFieldsByColSpan = (fields: AdditionalFields) => {
    return Object.fromEntries(
      Object.entries(fields).sort(
        ([, fieldA], [, fieldB]) => (fieldB.colSpan || 1) - (fieldA.colSpan || 1) // Descending order
      )
    );
  };
  

const SageFormBuilder: React.FC<DynamicFormBuilderProps> = ({
  fields,
  maxGridCount=1
}) => {
  const sortedFields = sortFieldsByColSpan(fields);
  const gridColClass:{[key:number]:string} = {
    1:'grid-cols-1',
    2:'grid-cols-2',
    3:'grid-cols-3',
  }
  const colSpanClass:{[key:number]:string} = {
    1:'col-span-1',
    2:'col-span-2',
    3:'col-span-3'
  }

  return (
    <div className={`grid w-full gap-4 ${gridColClass[maxGridCount]}`}>
      {Object.entries(sortedFields).map(([key, field]) => (
        <div key={key} className={`${colSpanClass[field.colSpan ?? 1]}`}>
          <CommonInputBuilder field={field} name={key} />
        </div>
      ))}
    </div>
  );
};

export default SageFormBuilder;
