import { useContext, useEffect, useState } from 'react';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext.tsx';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CardWrapperWithHeader,
  LabelledItemList,
  LabelledItemShape,
  Modal,
  ModalSettingsShape,
} from '@rabbit/elements/shared-components';
import moment from 'moment/moment';
import { formatDate, timeFormat } from '@rabbit/sage/utils/helpers.tsx';
import { DTRepairer_Public, FBD_Repairer_Public } from '@rabbit/data/types';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { SageFileUploader } from '@rabbit/sage/components/organisms/upload-wrapper/SageFileUploader.tsx';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import Skeleton from 'react-loading-skeleton';
import { UserIcon } from '@heroicons/react/24/solid';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import AssignInstallerHoldingModal from '@rabbit/sage/components/organisms/case-flow-modals/AssignInstallerHolding/AssignInstallerHoldingModal.tsx';

export default function ClaimInstallerDetailsSection() {
  const context = useContext(CaseflowContext);
  const { holdingData } = context || {};
  const { t } = useTranslation();
  const [fileMode, setFileMode] = useState(false);
  const [installer, setInstaller] = useState<DTRepairer_Public | null>(null);
  const [installerLoaded, setInstallerLoaded] = useState<boolean>(false);
  const [installerId, setInstallerId] = useState(
    context?.caseFacts?.delegate_installer_id!
  );

  const { installerPersona } = useGetMySagePersonas();

  const handleAssignInstallerModal = () => {
    setAssignInstallerModal(true);
  };

  const [assignInstallerModal, setAssignInstallerModal] =
    useState<boolean>(false);

  const assignInstallerModalSettings = {
    title: t('general.assignInstaller'),
    handleClose: () => setAssignInstallerModal(false),
  };

  useEffect(() => {
    void (async () => {
      if (!installerLoaded && installerId) {
        const installerData = await FBD_Repairer_Public.get(installerId);
        setInstallerLoaded(true);
        setInstaller(installerData!);
      }
      if (holdingData && !installerId) {
        setInstallerLoaded(true);
      }
    })();
  }, [installerId, holdingData]);
  const handleOpenInstallationAttachmentsModal = (totalFile: number) => {
    setFileMode(totalFile > 0);
  };
  const modalSettingsClaimFiles: ModalSettingsShape = {
    title: t('general.supportingMaterials'),
    handleClose: () => setFileMode(false),
  };
  const renderAttachments = () => {
    let totalFiles = holdingData?.installation_attachments?.length ?? 0;
    return (
      <div
        className={
          'flex cursor-pointer items-center gap-2 text-sm ' +
          (totalFiles === 0 ? 'text-red-500' : 'text-black')
        }
        onClick={() => handleOpenInstallationAttachmentsModal(totalFiles)}
      >
        {totalFiles} {t('general.filesUploaded')}
        <div>
          <ArrowTopRightOnSquareIcon
            className={
              'h-4 w-4' + (totalFiles === 0 ? 'text-red-500' : 'text-black')
            }
          />
        </div>
      </div>
    );
  };
  const recordRow: LabelledItemShape[] = [
    {
      label: t('general.contractorName'),
      value: installer?.orgName ?? '-',
    },
  ];
  const recordRow1: LabelledItemShape[] = [
    {
      label: t('general.installerName'),
      value: installer?.first_name
        ? `${installer.first_name} ${installer.last_name}`
        : installer?.name
        ? `${installer.name}`
        : '',
    },
    {
      label: t('general.phone'),
      value: installer?.phone!,
    },
  ];
  const recordRow2: LabelledItemShape[] = [
    {
      label: t('general.email'),
      value: installer?.email!,
    },
  ];
  const recordRow3: LabelledItemShape[] = [
    {
      label: t('general.installationDate'),
      value: holdingData?.installation?.installation_date
        ? formatDate(
            new Date(holdingData?.installation?.installation_date ?? '')
          )
        : '-',
    },
    {
      label: t('general.installationTime'),
      value: holdingData?.installation?.installation_time
        ? timeFormat(
            String(holdingData?.installation?.installation_time),
            t('general.hours'),
            t('general.minutes')
          )
        : '-',
    },
    {
      label: t('general.supportingMaterials'),
      value: renderAttachments(),
    },
    {
      label: t('general.comment'),
      value: holdingData?.internal_comment ? (
        <div
          dangerouslySetInnerHTML={{
            __html: holdingData?.internal_comment ?? '',
          }}
        ></div>
      ) : (
        '-'
      ),
    },
  ];

  return (
    <CardWrapperWithHeader
      title={t('general.installationDetails')}
      headerRight={
        <div className="flex gap-4">
          <>
            {!installerPersona?.personaId && (
              <Button
                icon={<UserIcon />}
                type="button"
                kind="primary"
                size={'sm'}
                children={t('general.assignInstaller')}
                onClick={() => handleAssignInstallerModal()}
              />
            )}
          </>
        </div>
      }
    >
      {!installerLoaded && (
        <div>
          <div className="font-nunito mb-[30px] grid grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-2">
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col gap-3">
        {[recordRow, recordRow1, recordRow2, recordRow3].map((items, index) => (
          <LabelledItemList
            key={index}
            items={items}
            listWrapperClasses={`font-nunito grid ${
              items === recordRow2 ? 'grid-cols-1' : 'grid-cols-2'
            } gap-y-6 gap-x-4`}
          />
        ))}
      </div>
      {fileMode && (
        <Modal
          kind="generic"
          settings={modalSettingsClaimFiles}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <div className="px-5">
              <SageFileUploader
                label={t('general.installationAttachments')}
                identifiers={{
                  category: UploadedFileCategories.SerialNumberProof,
                  docType: {
                    docid: holdingData?.holdingId || '',
                    type: DocTypeShapeTypes.Case,
                  },
                  personaId: holdingData?.holdingId ?? '',
                }}
                disabled={true}
                allowDelete={false}
                accepts={['image/*', '.pdf']}
                shouldAutoUpdateDocs={false}
                maxFiles={1}
                currentFiles={holdingData?.installation_attachments ?? []}
              />
            </div>
          </div>
        </Modal>
      )}
      {assignInstallerModal && (
        <div>
          <AssignInstallerHoldingModal
            handleClose={() => setAssignInstallerModal(false)}
            modalSettings={assignInstallerModalSettings}
            holding_proxy={null}
            setInstallerId={setInstallerId}
            setInstallerLoaded={setInstallerLoaded}
            installerId={installerId}
            type="CLAIM"
          />
        </div>
      )}
    </CardWrapperWithHeader>
  );
}
