import { SetupGreaseGateway } from '@rabbit/grease/gateway';
import { GreaseCodex } from '@rabbit/grease/elements';

const BakedCodex: GreaseCodex = [
  {
    domain: 'localhost',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'hot-sage.web.app',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'launchpad-sage.web.app',
    app: 'sage',
    deployment: 'aus',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'eulaunchpad-sage.web.app',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'eudemo-sage.web.app',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_demo',
  },
  {
    domain: 'eusandbox-sage.web.app',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_shelta',
  },
];

SetupGreaseGateway(BakedCodex);
