import {
  asyncValidateSchema,
  Button,
  getCountryByLabel,
  Heading,
  ButtonLink,
  Input,
} from '@rabbit/elements/shared-components';
import { Form, Formik } from 'formik';
import { CreateNewConsumerForm_FieldNames } from '@rabbit/sage/components/organisms/ModalNewRegistration/tenant-config/types.ts';
import { CONTACT_OPTIONS } from '@rabbit/bizproc/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Dispatch, SetStateAction, useContext, useRef, useState } from 'react';
import { NAME_VALIDATION_REGEX } from '@rabbit/sage/utils/consts.ts';
import { AppContext } from '@rabbit/app-context';
import { ModalEditAddAddress } from '@rabbit/sage/components/organisms/ModalEditAddAddress/ModalEditAddAddress.tsx';
import { Address } from '@rabbit/data/types';
import { printAddress } from '@rabbit/sage/utils/helpers.tsx';

interface customerDetailsProps {
  steps: any;
  setCustomerData: Dispatch<SetStateAction<any>>;
  setCurrentStep: Dispatch<SetStateAction<string>>;
}

export default function CustomerDetailsView({
  steps,
  setCustomerData,
  setCurrentStep,
}: customerDetailsProps) {
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formikRef = useRef(null) as any;
  const onSubmitCustomer = async (values: any) => {
    const data = {
      ...values,
      ...{ consumer_address: addresses },
      consumer_telephone:
        values.consumer_telephone_e164 ?? values.consumer_telephone,
    };
    setCustomerData(data);
    setCurrentStep(steps[2]);
  };
  const [addresses, setAddresses] = useState<Address>();
  const [showModal, setShowModal] = useState(false);
  const backToInstaller = () => void setCurrentStep(steps[0]);

  const initialValues: Partial<any> = {
    [CreateNewConsumerForm_FieldNames.consumer_first_name]: '',
    [CreateNewConsumerForm_FieldNames.consumer_last_name]: '',
    [CreateNewConsumerForm_FieldNames.consumer_email]: '',
    [CreateNewConsumerForm_FieldNames.consumer_telephone]: '',
    [CreateNewConsumerForm_FieldNames.consumer_preferred_contact]: [],
    [CreateNewConsumerForm_FieldNames.consumer_address]: {
      address_line_1: '',
      address_line_2: '',
      town: '',
      state: '',
      country: tenantInfo?.country ?? 'US',
    },
    type_of_customer: 'residential',
  };

  const validationSchemaRegister = () =>
    Yup.object().shape({
      [CreateNewConsumerForm_FieldNames.consumer_first_name]: Yup.string()
        .required(t('message.pleaseEnterFirstName'))
        .matches(
          NAME_VALIDATION_REGEX,
          t('message.firstNameMustStartWithCapitalAndContainOnlyLetters')
        ),
      [CreateNewConsumerForm_FieldNames.consumer_last_name]: Yup.string()
        .trim()
        .required(t('message.pleaseEnterLastName'))
        .matches(
          NAME_VALIDATION_REGEX,
          t('message.lastNameMustStartWithCapitalAndContainOnlyLetters')
        ),
      [CreateNewConsumerForm_FieldNames.consumer_email]: Yup.string()
        .email(t('message.pleaseEnterAValidEmailAddress'))
        .required(t('message.emailCannotBeEmpty')),
      [CreateNewConsumerForm_FieldNames.consumer_telephone]: Yup.string()
        .min(6, t('message.phoneNumberMinLength'))
        .required(t('message.pleaseEnterAPhoneNumber')),
      [CreateNewConsumerForm_FieldNames.consumer_preferred_contact]: Yup.array()
        .min(1, t('message.selectAtLeastOneContactMethod'))
        .typeError(t('message.selectPreferredContactMethod'))
        .required(t('message.selectPreferredContactMethod')),
      [CreateNewConsumerForm_FieldNames.consumer_address]: Yup.mixed(),
    });

  const handleAddAddress = () => {
    setShowModal(true);
  };

  const handleAddressEdit = (newAddress: Address) => {
    setAddresses(newAddress);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={asyncValidateSchema(validationSchemaRegister())}
        validateOnBlur={false}
        validateOnChange={true}
        onSubmit={onSubmitCustomer}
        innerRef={formikRef}
      >
        {({ values, errors }) => (
          <Form className="flex flex-col gap-3">
            <div className="grid grid-cols-[1fr] gap-4 md:grid-cols-2 md:p-2">
              <Input
                type="select"
                label={t('general.customerType')}
                name="type_of_customer"
                settings={{
                  options: [
                    {
                      value: 'residential',
                      label: t('general.residential'),
                    },
                    { value: 'business', label: t('general.business') },
                  ],
                  placeholder: t('general.customerType'),
                  id: 'type_of_customer',
                }}
              />

              <Input
                type="text"
                name={CreateNewConsumerForm_FieldNames.consumer_first_name}
                label={`${t('general.firstName')}*`}
                settings={{
                  id: CreateNewConsumerForm_FieldNames.consumer_first_name,
                  placeholder: t('general.firstName'),
                  hint: t('*required'),
                  forceUpperCaseFirstLetter: true,
                }}
              />

              <Input
                type="text"
                name={CreateNewConsumerForm_FieldNames.consumer_last_name}
                label={`${t('general.lastName')}*`}
                settings={{
                  id: CreateNewConsumerForm_FieldNames.consumer_last_name,
                  placeholder: t('general.lastName'),
                  hint: t('*required'),
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </div>
            <div className="grid grid-cols-1 gap-2">
              <Input
                type="email"
                name={CreateNewConsumerForm_FieldNames.consumer_email}
                label={`${t('general.email')}*`}
                settings={{
                  id: CreateNewConsumerForm_FieldNames.consumer_email,
                  placeholder: t('example@example.com'),
                  hint: t('*required'),
                }}
              />
            </div>
            <div className="xs:grid xs:grid-cols-1 xxs1:grid xxs1:grid-cols-1 grid grid-cols-2 gap-3 md:grid md:grid-cols-2">
              <Input
                type="phone"
                name={CreateNewConsumerForm_FieldNames.consumer_telephone}
                label={`${t('general.phoneNumber')}*`}
                settings={{
                  id: CreateNewConsumerForm_FieldNames.consumer_telephone,
                  placeholder: t('general.phoneNumber'),
                  hint: t('*required'),
                }}
              />
              <Input
                type="select"
                name={
                  CreateNewConsumerForm_FieldNames.consumer_preferred_contact
                }
                label={`${t('general.preferredContactMethod')}*`}
                settings={{
                  isMulti: true,
                  id: CreateNewConsumerForm_FieldNames.consumer_preferred_contact,
                  placeholder: t('message.pleaseSelectAnOption'),
                  options: CONTACT_OPTIONS,
                  errors: '',
                  hint: t('*required'),
                }}
              />
            </div>
            <div className="grid grid-cols-1 gap-2">
              <div className="mt-0 mb-4">
                <ButtonLink
                  className="font-nunito text-base font-medium"
                  label={t('general.customerAddress')}
                  onClick={handleAddAddress}
                >
                  {!addresses ? (
                    <span className="italic text-gray-400">
                      {t('message.pleaseEnterAddress')}
                    </span>
                  ) : (
                    printAddress(addresses)
                  )}
                </ButtonLink>
              </div>
            </div>
            <div className="mt-4 grid grid-cols-1 gap-4 md:col-span-2 md:grid-cols-2 md:p-2">
              <Button
                className="w-full"
                kind="primary"
                type="submit"
                loading={isSubmitting}
              >
                {t('general.continue')}
              </Button>
              <Button
                className="w-full"
                kind="outline_red"
                type="button"
                onClick={backToInstaller}
              >
                {t('general.back')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {showModal && (
        <ModalEditAddAddress
          address={addresses}
          handleClose={() => setShowModal(false)}
          handleSubmit={handleAddressEdit}
        />
      )}
    </>
  );
}
